/** @format */

import {linkResolver} from '../lib/linkResolver';
import {fonts} from '../styles/js/fonts';
interface LinkProp {
	target: string;
	title: string;
	url: string;
}

interface ButtonProp {
	link: LinkProp;
	buttonColor: string;
	colorScheme: string;
	style: string;
}

const mapLink = (linkData: LinkProp) => {
	const {target = '', title = '', url = '#'} = linkData;
	return {
		target,
		title,
		url: linkResolver(url),
	};
};

export const getLinksMapping = data => {
	if (!data) return null;

	const links = Array.isArray(data)
		? data.map((link: LinkProp) => mapLink(link))
		: [mapLink(data)];

	return links;
};

export function removeTypenameProp(jsonData) {
	if (jsonData && typeof jsonData === 'object') {
		if (Array.isArray(jsonData)) {
			return jsonData.map(item => removeTypenameProp(item));
		} else {
			const newObj = {};
			for (let key in jsonData) {
				if (key !== '__typename') {
					newObj[key] = removeTypenameProp(jsonData[key]);
				}
			}
			return newObj;
		}
	}
	return jsonData;
}

const mapButton = (buttonData: ButtonProp) => {
	const {
		link: {target, title, url},
		buttonColor,
		colorScheme,
		style,
	} = buttonData;

	return {
		colorScheme: colorScheme || buttonColor,
		style,
		target,
		title,
		url: linkResolver(url),
	};
};

export const getButtonMapping = (data: ButtonProp | ButtonProp[] | null) => {
	if (!data) return null;

	const buttons = Array.isArray(data)
		? data.map((button: ButtonProp) => mapButton(button))
		: [mapButton(data)];

	return buttons;
};

export const getMostFrequent = arr => {
	let frequency = {};
	let max = 0;
	let result;
	for (let v in arr) {
		frequency[arr[v]] = (frequency[arr[v]] || 0) + 1;
		if (frequency[arr[v]] > max) {
			max = frequency[arr[v]];
			result = arr[v];
		}
	}
	return result;
};

export const getDomainColorScheme = (subdomain: DomainProp) => {
	switch (subdomain) {
		case 'college':
			return 'aqua';
		case 'garden':
			return 'pine';
		case 'events':
			return 'gold';
		case 'thegregory':
			return 'fall';
		default:
			return 'maroon';
	}
};

type DomainProp =
	| 'harlaxton'
	| 'college'
	| 'tickets'
	| 'events'
	| 'garden'
	| 'thegregory'
	| string;

export const getDomainFontFamily = (
	subdomain?: DomainProp,
	custom?: string,
): string => {
	let buttonFontFamily = fonts.copernicusGalaxyBook;
	switch (subdomain) {
		case 'harlaxton':
		case 'events':
		case 'tickets':
			buttonFontFamily = fonts.copernicusGalaxyBook;
			break;
		case 'college':
		case 'garden':
			buttonFontFamily = fonts.eDNimpkishRegular;
			break;
		case 'thegregory':
			buttonFontFamily = fonts.GillSansBold;
			break;
		default:
			buttonFontFamily =
				custom === 'eDNimpkishRegular'
					? fonts.eDNimpkishRegular
					: fonts.copernicusGalaxyBook;

			break;
	}

	return buttonFontFamily;
};

export const identifySubdomain = (postLink: string): string => {
	let subdomain = 'harlaxton';
	if (!postLink) return subdomain;

	const urlObject = new URL(postLink);
	const hostname = urlObject.hostname;

	// extract the subdomain from the hostname
	subdomain = hostname.split('.')[0];

	switch (subdomain) {
		case 'college':
			subdomain = 'college';
			break;
		case 'events':
		case 'tickets':
			subdomain = 'events';
			break;
		case 'garden':
			subdomain = 'garden';
			break;
		case 'thegregory':
			subdomain = 'thegregory';
			break;
		default:
			subdomain = 'harlaxton';
			break;
	}

	return subdomain;
};
