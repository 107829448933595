/** @format */

import React from 'react';
import {Box} from '@chakra-ui/react';
import {fonts} from '../../styles/js/fonts';
import { textStyles } from '../../styles/js/textStyles';
import theme from '../../styles/js/theme';

export function WYSIWYG({html, variant = '', subdomain, ...containerProps}) {
	/* style class will need a corresponding global style config object in theme.js */
	const style = variant ? `WYSIWYG--${variant}` : `WYSIWYG--default`;
	const fontVariantClass = subdomain === 'thegregory' ? 'thegregory' : '';
	const combinedClass = `WYSIWYG ${style} ${fontVariantClass}`;

	const WYSIWYG = props => <Box className={combinedClass.trim()} {...props} />;

	return (
		<>
			<WYSIWYG dangerouslySetInnerHTML={html} {...containerProps} />
		</>
	);
}
